/* eslint-disable @typescript-eslint/camelcase */
import { errorLogging } from "@/global/services/errorLogging";

/* type coordinates = "lat" | "lng";

const position: Record<coordinates, number | undefined> = {
  lat: undefined,
  lng: undefined
}; */

export const geolocation: any = {
  errorHandler: function(error: any, callback?:any): void {
    switch (error.code) {
      case error.PERMISSION_DENIED:
        errorLogging.setWarning("Du har blokeret brug af GPS i browseren.");
        break;
      case error.POSITION_UNAVAILABLE:
        errorLogging.setWarning("Location information is unavailable.");
        break;
      case error.TIMEOUT:
        errorLogging.setWarning("The request to get user location timed out.");
        break;
      case error.UNKNOWN_ERROR:
        errorLogging.setWarning("An unknown error occurred.");
        break;
    }
  }
};
