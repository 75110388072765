<template>
  <div class="grid grid-cols-2 grid-rows-3 gap-x-6 gap-y-1">
    <span
      @click="hoursUp()"
      class="flex flex-shrink-0 justify-center items-center text-yellow col-span-1 rounded-md border-2 border-green-dark cursor-pointer"
    >
      <i class="fas fa-angle-up"></i>
    </span>
    <span
      @click="minutesUp()"
      class="flex flex-shrink-0 justify-center items-center text-yellow col-span-1 rounded-md border-2 border-green-dark cursor-pointer"
    >
      <i class="fas fa-angle-up"></i>
    </span>
    <span class="col-span-1 flex">
      <input
        @input="
          $emit('update:modelValue', returnTime($event.target.value, minutes))
        "
        class="text-green-dark rounded-md bg-white w-full text-center"
        type="number"
        v-bind:value="hours < 10 ? '0' + hours : hours"
      />
      <span v-if="duration" class="text-green font-bold ml-3">{{
        t("time-picker-new.hours")
      }}</span>
    </span>
    <span class="col-span-1 flex">
      <input
        @input="
          $emit('update:modelValue', returnTime(hours, $event.target.value))
        "
        class="text-green-dark rounded-md bg-white w-full text-center"
        type="number"
        v-bind:value="minutes < 10 ? '0' + minutes : minutes"
      />
      <span v-if="duration" class="text-green font-bold ml-3">{{
        t("time-picker-new.minutes")
      }}</span>
    </span>
    <span
      @click="hoursDown()"
      class="flex flex-shrink-0 justify-center items-center text-yellow col-span-1 rounded-md border-2 border-green-dark cursor-pointer"
    >
      <i class="fas fa-angle-down"></i>
    </span>
    <span
      @click="minutesDown()"
      class="flex flex-shrink-0 justify-center items-center text-yellow col-span-1 rounded-md border-2 border-green-dark cursor-pointer"
    >
      <i class="fas fa-angle-down z-0"></i>
    </span>
  </div>
</template>

<script>
import { ref, computed } from "vue";
import { useI18n } from "vue-i18n";
export default {
  name: "TimePicker",
  emits: ["update:modelValue"],
  props: {
    modelValue: Number,
    duration: {
      type: Boolean,
      default: false
    },
    utc: {
      type: Boolean,
      default: false
    }
  },
  setup(props, { emit }) {
    const { t } = useI18n();

    const state = {
      hours: computed(() => {
        const d = new Date(props.modelValue);
        return props.utc ? d.getUTCHours() : d.getHours();
      }),
      minutes: computed(() => {
        const d = new Date(props.modelValue);
        return props.utc ? d.getUTCMinutes() : d.getMinutes();
      })
    };

    const functions = {
      hoursUp: () => {
        const h = state.hours.value === 23 ? 0 : state.hours.value + 1;
        emit("update:modelValue", functions.returnTime(h, state.minutes.value));
      },

      hoursDown: () => {
        const h = state.hours.value === 0 ? 23 : state.hours.value - 1;
        emit("update:modelValue", functions.returnTime(h, state.minutes.value));
      },

      minutesUp: () => {
        const m = state.minutes.value === 59 ? 0 : state.minutes.value + 1;
        emit("update:modelValue", functions.returnTime(state.hours.value, m));
      },

      minutesDown: () => {
        const m = state.minutes.value === 0 ? 59 : state.minutes.value - 1;
        emit("update:modelValue", functions.returnTime(state.hours.value, m));
      },

      returnTime: (h, m) => {
        const date = new Date(props.modelValue);
        props.utc ? date.setUTCHours(h, m, 0, 0) : date.setHours(h, m, 0, 0);
        return date.getTime();
      }
    };

    return { t, ...state, ...functions };
  }
};
</script>

<style scoped>
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}
</style>
