<template>
  <Header :title="t('title')" icon="fa-stopwatch" />
  <Content>
    <div class="w-full h-full flex flex-col items-center justify-center">
      <section
        class="flex items-center w-4/5 justify-center bg-green-darkest border-2 border-green-dark p-2 text-white  text-center rounded-lg mt-4"
      >
        <span class="flex">
          <span>
            <p class="text-6xl text-yellow">{{ hours }}</p>
            <p class="uppercase text-green">
              {{ t(`timeRegistration.h`) }}
            </p>
          </span>
          <p class="text-6xl mx-4">:</p>
        </span>
        <span class="flex">
          <span>
            <p class="text-6xl text-yellow">{{ minutes }}</p>
            <p class="uppercase text-green">min</p>
          </span>
          <p class="text-6xl mx-4">:</p>
        </span>
        <span class="flex flex-col">
          <p class="text-6xl text-yellow">{{ seconds }}</p>
          <p class="uppercase text-green">sec</p>
        </span>
      </section>

      <StartButton
        v-if="!startDisabled"
        v-model="startDisabled"
        @getTime="getTime()"
      />
      <StopButton
        v-if="startDisabled"
        v-model="startDisabled"
        :runningTime="runningTime"
        @stopTime="stopTime()"
      />
      <NewRequestButton v-if="!startDisabled" />
    </div>
  </Content>
</template>

<script>
import { ref } from "vue";

import StartButton from "@/components/TimeRegistration/Clock/StartButton";
import StopButton from "@/components/TimeRegistration/Clock/StopButton";
import NewRequestButton from "@/components/TimeRegistration/Clock/NewRequestButton";
import Header from "@/components/Header";
import Content from "@/components/Content";
import { errorLogging } from "@/global/services/errorLogging";
import { timeRegistration } from "@/timeregistration/services/timeRegistration";
import { useI18n } from "vue-i18n";

export default {
  name: "TimeRegistration",
  components: {
    StartButton,
    StopButton,
    NewRequestButton,
    Header,
    Content
  },
  setup() {
    const { t } = useI18n({
      messages: {
        en: { title: "Time Registration" },
        dk: { title: "Tidsregistrering" }
      }
    });
    const state = {
      hours: ref("0"),
      minutes: ref("0"),
      seconds: ref("0"),
      startDisabled: ref(false),
      runningTime: ref(false),
      timer: ref(null)
    };

    function startTimer() {
      state.timer.value = setInterval(() => {
        const date = new Date();

        const start = new Date(state.runningTime.value.data().start);
        const diff = date - start;
        state.hours.value = Math.floor(diff / 36e5);
        state.minutes.value = Math.floor((diff % 36e5) / 6e4);
        state.seconds.value = Math.floor((diff % 6e4) / 1e3);
      }, 1000);
    }

    const functions = {
      stopTime: function() {
        clearInterval(state.timer.value);
        state.runningTime.value = false;
        state.timer.value = null;
        state.hours.value = 0;
        state.minutes.value = 0;
        state.seconds.value = 0;
      },
      getTime: function() {
        timeRegistration
          .getTimeStarted()
          .then(time => {
            if (time !== false) {
              state.runningTime.value = time;
              state.startDisabled.value = true;
              startTimer();
            } else {
              state.startDisabled.value = false;
            }
          })
          .catch(message => errorLogging.setError(message));
      }
    };

    functions.getTime();

    return {
      t,
      ...state,
      ...functions
    };
  }
};
</script>
