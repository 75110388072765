<template>
  <Button
    :label="t(`timeRegistration.requestTime`)"
    icon="fa-hand-holding-heart"
    class="mt-3 bg-green w-3/5 flex justify-center"
    @click="openModal()"
  />

  <Modal @closeModal="modalTime = null" :visible="modalTime !== null">
    <span
      class=" flex-col flex flex-shrink-0 justify-center items-center text-xl bg-green rounded-full w-14 h-14 z-10 mb-3 text-white"
    >
      <i class="fas fa-hand-holding-heart"></i>
    </span>
    <p class="text-lg font-semibold">
      Anmod om at få <span class="text-yellow">tilføjet</span> en registrering.
    </p>
    <form
      @keyup.enter="submitNewRequest"
      class="w-full md:w-4/5 lg:w-3/5 flex flex-col items-center justify-center my-3 md:my-6"
    >
      <span
        class="flex flex-col md:flex-row items-center md:justify-between mb-3 w-full md:-ml-12"
      >
        <p class="font-semibold text-green">Dato</p>
        <DatePicker
          v-model="modalTime.start"
          class="text-green-darkest bg-white"
        />
      </span>
      <span
        class="flex flex-col md:flex-row items-center md:justify-between mb-3 w-full md:-ml-12"
      >
        <p class="font-semibold text-green">Start tid</p>
        <TimePickerNew v-model="modalTime.start" />
      </span>

      <span
        class="flex flex-col md:flex-row items-center md:justify-between mb-3 w-full md:-ml-12"
      >
        <p class="font-semibold text-green">Slut tid</p>
        <TimePickerNew v-model="modalTime.end" />
      </span>
    </form>
    <Button
      class="bg-gradient-to-r from-yellow-dark to-yellow hover:from-yellow hover:to-yellow-dark"
      label="Tilføj anmodning"
      @click="submitNewRequest"
    />
  </Modal>
</template>

<script>
import { ref, watchEffect } from "vue";

import Button from "@/components/Button";
import Modal from "@/components/Modal";
import DatePicker from "@/components/DatePicker";
import TimePickerNew from "@/components/TimePickerNew";
import { errorLogging } from "@/global/services/errorLogging";
import { authentication } from "@/global/services/authentication";
import { requests } from "@/global/services/requests";
import { systemState } from "@/global/services/systemState";
import { timeRegistration } from "@/timeregistration/services/timeRegistration";
import { useI18n } from "vue-i18n";

export default {
  name: "NewRequestButton",
  components: {
    Button,
    Modal,
    DatePicker,
    TimePickerNew
  },
  setup() {
    const { t } = useI18n({
      messages: {
        en: {},
        dk: {}
      }
    });
    const state = {
      modalTime: ref(null)
    };

    const functions = {
      openModal: function() {
        const currentDate = new Date();
        const data = {
          end: currentDate.getTime(),
          start: currentDate.getTime() - 1000 * 60 * 60,
          uid: authentication.getUserUid(),
          unit: authentication.getUserProfile().selectedUnit
        };
        state.modalTime.value = data;
      },

      submitNewRequest: async function() {
        systemState.startProcessing();
        const now = new Date();
        if (state.modalTime.value.end < state.modalTime.value.start) {
          systemState.stopProcessing();
          errorLogging.setWarning("Slut tiden kan ikke være før start tiden.");
        } else if (state.modalTime.value.end > now.getTime()) {
          systemState.stopProcessing();
          errorLogging.setWarning("Du kan ikke anmode om tid i fremtiden.");
        } else {
          if (
            !(await timeRegistration.checkTimeExists(state.modalTime.value))
          ) {
            const changeType = "TimeCreate";
            requests
              .createRequest(null, state.modalTime.value, null, changeType)
              .then(message => {
                state.modalTime.value = null;
                systemState.stopProcessing();
                errorLogging.setSuccess(message);
              })
              .catch(message => {
                errorLogging.setError(message, "Line 121", "NewRequestButton");
                systemState.stopProcessing();
              });
          } else {
            errorLogging.setWarning(
              "Du har allerede registreret tid i dette tidsrum."
            );
            systemState.stopProcessing();
          }
        }
      }
    };

    watchEffect(() => {
      if (state.modalTime.value !== null) {
        const replaceDate = new Date(state.modalTime.value.end);
        const startDate = new Date(state.modalTime.value.start);
        replaceDate.setMonth(startDate.getMonth());
        replaceDate.setDate(startDate.getDate());
        state.modalTime.value.end = replaceDate.getTime();
      }
    });

    return { t, ...state, ...functions };
  }
};
</script>

<style scoped></style>
