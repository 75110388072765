<template>
  <Button
    :label="t(`timeRegistration.startTime`)"
    :disabled="modelValue"
    class="mt-3 bg-green w-3/5"
    @click="submitStart()"
  ></Button>
</template>

<script>
import Button from "@/components/Button";
import { timeRegistration } from "@/timeregistration/services/timeRegistration";
import { systemState } from "@/global/services/systemState";
import { geolocation } from "@/global/services/geolocation";
import { units } from "@/global/services/units";
import { errorLogging } from "@/global/services/errorLogging";
import ipify from "ipify2";
import { useI18n } from "vue-i18n";

export default {
  name: "StartButton",
  emits: ["update:modelValue", "getTime"],
  components: {
    Button
  },
  props: {
    modelValue: Boolean,
    time: Object
  },
  setup(props, { emit }) {
    const { t } = useI18n({
      messages: {
        en: {},
        dk: {}
      }
    });
    const options = {
      enableHighAccuracy: true,
      timeout: 5000,
      maximumAge: 0
    };

    function between(x, min, max) {
      return x >= min && x <= max;
    }

    function toggleButton(isProcessing, isTimeStarted) {
      isProcessing
        ? systemState.startProcessing()
        : systemState.stopProcessing();
      isTimeStarted
        ? emit("update:modelValue", true)
        : emit("update:modelValue", false);
    }

    const functions = {
      submitStart: function() {
        toggleButton(true, true);
        if (units.getSelectedUnit().data().usingIp) {
          ipify
            .ipv4()
            .then(ipv4 => {
              if (units.getSelectedUnit().data().ipAddress === ipv4) {
                timeRegistration
                  .startTimeRegistration()
                  .then(message => {
                    toggleButton(false, true);
                    errorLogging.setSuccess(message);
                    emit("getTime");
                  })
                  .catch(message => {
                    errorLogging.setError(message);
                    toggleButton(false, false);
                  });
              } else {
                toggleButton(false, false);
                errorLogging.setWarning(
                  "Du er ikke forbundet til gyldigt wifi"
                );
              }
            })
            .catch(err => {
              toggleButton(false, false);
              errorLogging.setError(err);
            });
        } else if (units.getSelectedUnit().data().usingGps) {
          navigator.geolocation.getCurrentPosition(
            geo => {
              const lat = geo.coords.latitude;
              const latMin = units.getSelectedUnit().data().gpsBounds1[0];
              const latMax = units.getSelectedUnit().data().gpsBounds2[0];
              const lng = geo.coords.longitude;
              const lngMin = units.getSelectedUnit().data().gpsBounds1[1];
              const lngMax = units.getSelectedUnit().data().gpsBounds2[1];
              if (
                between(lat, latMin, latMax) &&
                between(lng, lngMin, lngMax)
              ) {
                timeRegistration
                  .startTimeRegistration()
                  .then(message => {
                    toggleButton(false, true);
                    errorLogging.setSuccess(message);
                    emit("getTime");
                  })
                  .catch(message => {
                    errorLogging.setError(message);
                    toggleButton(false, false);
                  });
              } else {
                errorLogging.setWarning(
                  "Ifølge gps'en er du ikke på din arbejdsplads"
                );
                toggleButton(false, false);
              }
            },

            error =>
              geolocation.errorHandler(error, toggleButton(false, false)),
            options
          );
        } else if (units.getSelectedUnit().data().usingGpsLog) {
          navigator.geolocation.getCurrentPosition(
            geo => {
              const lat = geo.coords.latitude;
              const lng = geo.coords.longitude;
              timeRegistration
                .startTimeRegistrationWithGPSLog(lat, lng)
                .then(message => {
                  toggleButton(false, true);
                  errorLogging.setSuccess(message);
                  emit("getTime");
                })
                .catch(message => {
                  errorLogging.setError(message);
                  toggleButton(false, false);
                });
            },

            error =>
              geolocation.errorHandler(error, toggleButton(false, false)),
            options
          );
        } else {
          timeRegistration
            .startTimeRegistration()
            .then(message => {
              toggleButton(false, true);
              errorLogging.setSuccess(message);
              emit("getTime");
            })
            .catch(message => {
              errorLogging.setError(message);
              toggleButton(false, false);
            });
        }
      }
    };

    return { t, ...functions };
  }
};
</script>

<style scoped></style>
