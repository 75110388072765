<template>
  <Button
    :label="t(`timeRegistration.stopTime`)"
    :disabled="!modelValue"
    class="mt-3 bg-red-400 w-3/5"
    @click="openModal()"
  ></Button>
  <Modal
    @closeModal="showBreakModal = !showBreakModal"
    :visible="showBreakModal"
  >
    <div class="flex items-center w-full my-1">
      <span
        class="flex flex-shrink-0 justify-center items-center bg-green rounded-full w-10 h-10 z-10 mr-3"
      >
        <i class="fas fa-coffee"></i>
      </span>
      <p class="text-lg font-semibold">
        {{ t("stop-button.description1")
        }}<span class="text-yellow">{{ t("stop-button.description2") }}</span>
      </p>
    </div>

    <TimePickerNew
      utc="true"
      duration="true"
      class="my-3"
      v-model="breakDuration"
    />

    <div class="flex items-center w-full my-1">
      <span
        class="flex flex-shrink-0 justify-center items-center bg-green rounded-full w-10 h-10 z-10 mr-3"
      >
        <i class="fas fa-file-alt"></i>
      </span>
      <p class="text-lg font-semibold">
        <span class="text-yellow">{{ t("stop-button.description3") }}</span
        >{{ t("stop-button.description4") }}
      </p>
    </div>

    <Textarea v-model="note" class="mt-3 mb-6 w-full" />

    <div class="grid grid-cols-2 gap-6">
      <Button
        class=" col-span-1 bg-green-light"
        :label="t('stop-button.finish')"
        @click="submitStop()"
      />
      <Button
        class="col-span-1 bg-red-500"
        :label="t('stop-button.cancel')"
        @click="showBreakModal = !showBreakModal"
      />
    </div>
  </Modal>
</template>

<script>
import { ref, watch } from "vue";
import Modal from "@/components/Modal";
import TimePickerNew from "@/components/TimePickerNew";
import Textarea from "@/components/Textarea";
import Button from "@/components/Button";
import { timeRegistration } from "@/timeregistration/services/timeRegistration";
import { systemState } from "@/global/services/systemState";
import { geolocation } from "@/global/services/geolocation";
import { units } from "@/global/services/units";
import { errorLogging } from "@/global/services/errorLogging";
import ipify from "ipify2";
import { authentication } from "@/global/services/authentication";
import { useI18n } from "vue-i18n";

export default {
  name: "StopButton",
  emits: ["update:modelValue", "stopTime"],
  components: {
    Button,
    Modal,
    TimePickerNew,
    Textarea
  },
  props: {
    modelValue: Boolean,
    runningTime: Object
  },
  setup(props, { emit }) {
    const { t } = useI18n();
    const state = {
      breakDuration: ref(1000 * 60 * 30),
      showBreakModal: ref(false),
      userProfile: authentication.getUserProfile(true),
      note: ref("")
    };

    watch(
      state.userProfile.value,
      () => {
        if (state.userProfile.value.breakDuration !== undefined) {
          state.breakDuration.value = state.userProfile.value.breakDuration;
        }
      },
      { immediate: true }
    );

    function validateBreak() {
      const d = new Date();
      const workDuration = d.getTime() - props.runningTime.data().start;
      return workDuration > state.breakDuration.value;
    }

    const options = {
      enableHighAccuracy: true,
      timeout: 5000,
      maximumAge: 0
    };

    function between(x, min, max) {
      return x >= min && x <= max;
    }

    function toggleButton(isProcessing, isTimeStarted) {
      isProcessing
        ? systemState.startProcessing()
        : systemState.stopProcessing();
      isTimeStarted
        ? emit("update:modelValue", true)
        : emit("update:modelValue", false);
    }

    const functions = {
      openModal: function() {
        state.showBreakModal.value = true;
      },

      submitStop: function() {
        if (!validateBreak()) {
          errorLogging.setWarning(
            "Pausen kan ikke være længere end arbejdstiden"
          );
        } else {
          const breakDuration = state.breakDuration.value;
          if (breakDuration !== state.userProfile.value.breakDuration) {
            authentication.updateUser(authentication.getUserUid(), {
              breakDuration
            });
          }
          toggleButton(true, true);
          if (units.getSelectedUnit().data().usingIp) {
            ipify
              .ipv4()
              .then(ipv4 => {
                if (units.getSelectedUnit().data().ipAddress === ipv4) {
                  timeRegistration
                    .stopTimeRegistration(
                      props.runningTime.id,
                      breakDuration,
                      state.note.value,
                      state.note.value
                    )
                    .then(message => {
                      emit("stopTime");
                      errorLogging.setSuccess(message);
                      toggleButton(false, false);
                    })
                    .catch(message => {
                      toggleButton(false, true);
                      errorLogging.setError(message);
                    });
                } else {
                  toggleButton(false, true);
                  errorLogging.setWarning(
                    "Du er ikke forbundet til gyldigt wifi"
                  );
                }
              })
              .catch(err => {
                toggleButton(false, true);
                errorLogging.setError(err);
              });
          } else if (units.getSelectedUnit().data().usingGps) {
            navigator.geolocation.getCurrentPosition(
              geo => {
                errorLogging.setAnalytics({
                  message: "Accuracy GPS",
                  accuracy: geo.coords.accuracy
                });
                const lat = geo.coords.latitude;
                const latMin = units.getSelectedUnit().data().gpsBounds1[0];
                const latMax = units.getSelectedUnit().data().gpsBounds2[0];
                const lng = geo.coords.longitude;
                const lngMin = units.getSelectedUnit().data().gpsBounds1[1];
                const lngMax = units.getSelectedUnit().data().gpsBounds2[1];
                if (
                  between(lat, latMin, latMax) &&
                  between(lng, lngMin, lngMax)
                ) {
                  timeRegistration
                    .stopTimeRegistration(
                      props.runningTime.id,
                      breakDuration,
                      state.note.value,
                      state.note.value
                    )
                    .then(message => {
                      emit("stopTime");
                      errorLogging.setSuccess(message);
                      toggleButton(false, false);
                    })
                    .catch(message => {
                      toggleButton(false, true);
                      errorLogging.setError(message);
                    });
                } else {
                  errorLogging.setWarning(
                    "Ifølge gps'en er du ikke på din arbejdsplads"
                  );
                  toggleButton(false, true);
                }
              },
              error =>
                geolocation.errorHandler(error, toggleButton(false, true)),
              options
            );
          } else if (units.getSelectedUnit().data().usingGpsLog) {
            navigator.geolocation.getCurrentPosition(
              geo => {
                const lat = geo.coords.latitude;
                const lng = geo.coords.longitude;
                timeRegistration
                  .stopTimeRegistrationWithGPSLog(
                    props.runningTime.id,
                    breakDuration,
                    state.note.value,
                    lat,
                    lng
                  )
                  .then(message => {
                    emit("stopTime");
                    errorLogging.setSuccess(message);
                    toggleButton(false, false);
                  })
                  .catch(message => {
                    errorLogging.setError(message);
                    toggleButton(false, false);
                  });
              },

              error =>
                geolocation.errorHandler(error, toggleButton(false, false)),
              options
            );
          } else {
            timeRegistration
              .stopTimeRegistration(
                props.runningTime.id,
                breakDuration,
                state.note.value,
                state.note.value
              )
              .then(message => {
                emit("stopTime");
                errorLogging.setSuccess(message);
                toggleButton(false, false);
              })
              .catch(message => {
                toggleButton(false, true);
                errorLogging.setError(message);
              });
          }
        }
      }
    };

    return { t, ...state, ...functions };
  }
};
</script>

<style scoped></style>
