<template>
  <div class="">
    <textarea
      v-bind:value="modelValue"
      @input="$emit('update:modelValue', $event.target.value)"
      class="outline-none font-bold md:text-sm text-green p-3 rounded-md w-full"
      :placeholder="placeholder"
    />
    <span
      v-if="error"
      class="bg-white rounded-md text-red-500 w-full font-semibold inline-flex mt-3 mr-4 px-3 py-1"
    >
      <span class="flex justify-center items-center text-lg mr-3">
        <i class="fas fa-exclamation-circle"></i>
      </span>
      <p>
        {{ errorMessage }}
      </p>
    </span>
  </div>
</template>

<script>
export default {
  name: "Input",
  emits: ["update:modelValue"],
  props: {
    modelValue: String,
    placeholder: String,
    error: Boolean,
    errorMessage: String
  }
};
</script>

<style scoped>
::-webkit-input-placeholder {
  text-align: center;
}

:-moz-placeholder {
  text-align: center;
}

/* Enter and leave animations can use different */
/* durations and timing functions.              */
.slide-fade-enter-active {
  transition: all 0.3s ease-out;
}

.slide-fade-leave-active {
  transition: all 0.8s cubic-bezier(1, 0.5, 0.8, 1);
}

.slide-fade-enter-from,
.slide-fade-leave-to {
  transform: translateX(20px);
}
</style>
